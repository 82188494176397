<template>
  <li
    class="file-item"
  >
    <b>{{ file.name }}</b>
    <small :class="file.status.toLowerCase()">{{ file.status }}</small>

    <!-- VIEW BUTTON -->
    <div class="d-flex">
      <md-button
        class="md-sm md-info"
        :href="file.url"
        target="_blank"
      >
        VIEW
      </md-button>
    </div>

    <!-- UPDATE FORM -->
    <div v-if="isUpdating">
      <FileTypesSelect v-model="form.file_type_id" />

      <template v-if="form.file_type_id === 3">
        <StudentProgrammesSelect
          v-model="form.programme_id"
          :student-id="item.student_id"
          @change="onSelectProgramme"
        />
        <template v-if="package_id">
          <PackagePaymentsSelect
            :key="package_id"
            v-model="form.package_payment_id"
            force
            :package-id="package_id"
          />
          <FormText
            v-model="form.marketing_remark"
            label="Remark for Sales Team"
            icon="description"
          />
        </template>
      </template>

      <md-switch v-model="form.show_in_app">
        Show in APP
      </md-switch>
    </div>

    <!-- BUTTONS -->
    <div
      v-if="file.status === 'Pending' && !isUpdating"
      class="d-flex"
    >
      <md-button
        class="md-sm md-danger ml-0"
        @click="updateFile('Denied')"
      >
        DENY
      </md-button>
      <md-button
        class="md-sm md-success mr-0"
        @click="isUpdating = true"
      >
        ACCEPT
      </md-button>
    </div>
    <div
      v-if="isUpdating"
      class="d-flex"
    >
      <md-button
        class="md-sm md-success"
        @click="updateFile('Accepted')"
      >
        ACCEPT
      </md-button>
    </div>
    <!-- /BUTTONS -->
  </li>
</template>

<script>
import { FileTypesSelect, StudentProgrammesSelect, PackagePaymentsSelect } from '@/components/Inputs/selects';
import { FormText } from '@/components/Inputs';

export default {
  components: {
    FileTypesSelect, StudentProgrammesSelect, PackagePaymentsSelect, FormText,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    file: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isUpdating: false,
    package_id: null,
    form: {
      file_type_id: null,
      programme_id: null,
      package_payment_id: null,
      marketing_remark: null,
      show_in_app: false,
    },
  }),
  methods: {
    updateFile(status) {
      this.$emit('loading', true);
      this.request(`requests/${this.item.request_id}/file/${this.file.id}`, 'put', {
        status,
        ...this.form,
      }, (res) => {
        this.$emit('saved', res);
        this.fireSuccess('Request File updated successfully');
      }, () => {
        this.$emit('loading', false);
      });
    },
    onSelectProgramme(e) {
      this.package_id = e.package_id;
      this.form.package_payment_id = null;
    },
  },
};
</script>

<style scoped lang="scss">
li.file-item {
  padding-left: .5em;

  b {
    font-size: .85em;
    display: inline-block;
  }
  small {
    margin-left: 1em;
    padding: 2px 8px;
    border-radius: .25em;
    font-weight: 500;
    text-transform: uppercase;
    color: white;

    &.pending {
      background: var(--warning);
    }
    &.accepted {
      background: var(--success);
    }
    &.denied {
      background: var(--danger);
    }
  }

  .d-flex {
    > * {
      flex: 1;
    }
    button {
      margin: .25em;
    }
  }

  &.denied, &.accepted {
    button {
      display: none;
    }
  }
  &.denied {
    background: var(--danger);
  }
}
</style>
