<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-20">
            <RequestTypeCategoriesSelect
              v-model="filters.category_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <RequestTypesSelect
              v-model="filters.request_type_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <FormSelect
              v-model="filters.status"
              label="Status"
              icon="description"
              :options="statuses"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <form-date
              v-model="filters.date_from"
              label="Date From"
            />
          </div>
          <div class="md-layout-item md-size-20">
            <form-date
              v-model="filters.date_to"
              label="Date To"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="$refs.vtable.init()"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
              @onEdit="onEdit"
              @onShowLog="onShowLog"
              @onDeactivate="onDeactivate"
              @onGoToStudent="onGoToStudent"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <RequestsModal
      v-if="showModal"
      :item="selectedItem"
      @close="closeModal"
      @saved="$refs.vtable.init()"
    />

    <RequestsLogModal
      v-if="showLogModal"
      :item="selectedItem"
      @close="closeModal"
      @saved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormSelect, FormDate } from '@/components/Inputs';
import RequestTypesSelect from '@/components/Inputs/selects/RequestTypesSelect.vue';
import RequestTypeCategoriesSelect from '@/components/Inputs/selects/RequestTypeCategoriesSelect.vue';
import RequestsModal from './RequestsModal.vue';
import RequestsLogModal from './RequestsLogModal.vue';

export default {
  components: {
    VueTable,
    FormSelect,
    FormDate,
    RequestsModal,
    RequestsLogModal,
    RequestTypesSelect,
    RequestTypeCategoriesSelect,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'request_id',
            mask: 'ID',
            noFormat: true,
            sortable: true,
          },
          {
            title: 'request_type.title',
            mask: 'Type',
            sortable: true,
          },
          {
            title: 'date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'status',
            sortable: true,
            callback: (a) => (a === 'Denied' ? 'Not Approved' : a),
          },
          {
            title: 'student.student_number',
            mask: 'student number',
            sortable: true,
          },
          {
            title: 'student.full_name',
            mask: 'student',
            sortable: true,
          },
          {
            title: 'student.email',
            mask: 'student email',
            sortable: true,
          },
          {
            title: 'updater.name',
            mask: 'updated by',
            sortable: true,
          },
          {
            title: 'updated_at',
            mask: 'last update',
            sortable: true,
            dateTimeFormat: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Go To Student Resume',
            callback: 'onGoToStudent',
            icon: 'person',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Request Details',
            callback: 'onEdit',
            icon: 'search',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Show Request Log',
            callback: 'onShowLog',
            icon: 'visibility',
          },
        ],
        values: {},
      },
      loading: false,
      showModal: false,
      showLogModal: false,
      selectedItem: null,
      filters: {
        category_id: 'all',
        request_type_id: 'all',
        status: 'Pending',
        date_from: null,
        date_to: null,
        sortBy: 'date',
        sortDir: 'desc',
      },
      statuses: [
        { id: 'all', name: 'All' },
        { id: 'Pending', name: 'Pending' },
        { id: 'Ready for Collection', name: 'Ready for Collection' },
        { id: 'Completed', name: 'Completed' },
        { id: 'Denied', name: 'Not Approved' },
        { id: 'Cancelled', name: 'Cancelled' },
      ],
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getData(params) {
      this.loading = true;
      if (this.filters.status === 'Completed') {
        const completedCol = this.vTable.headers.find((x) => x.title === 'completed_at');

        if (!completedCol) {
          this.vTable.headers.push({
            title: 'completed_at',
            mask: 'completed at',
            sortable: true,
            dateTimeFormat: true,
          });
        }

        this.filters.sortBy = 'completed_at';
      } else {
        const compHeader = this.vTable.headers.find((x) => x.title === 'completed_at');
        if (compHeader) {
          this.vTable.headers.pop();
          this.filters.sortBy = 'date';
        }
      }

      this.request('requests', 'get', {
        ...params, ...this.filters,
      }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
    closeModal() {
      this.showModal = false;
      this.showLogModal = false;
      this.selectedItem = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
    onShowLog(item) {
      this.showLogModal = true;
      this.selectedItem = item;
    },
    onGoToStudent(item, isMiddleClick) {
      this.openLink({ name: 'StudentResume', params: { id: item.student_id } }, isMiddleClick);
    },
  },
};
</script>

<style scoped>
</style>
