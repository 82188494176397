<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      <b>{{ item.request_type.title }}</b>
    </template>
    <template slot="body">
      <ul>
        <li
          v-for="l of item.log"
          :key="l"
        >
          <b>{{ l.user }}</b>
          {{ l.message }}
          <small>{{ dateTimeFormat(l.created_at) }}</small>
        </li>
      </ul>

      <div
        class="response"
      >
        <form-textarea
          v-model="form.message"
          label="Remark"
        />
      </div>
    </template>
    <template slot="footer">
      <md-button
        class="md-primary md-sm"
        @click="saveLog"
      >
        SAVE
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormTextarea } from '@/components/Inputs';

export default {
  components: {
    FormTextarea,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      message: null,
    },
  }),
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveLog() {
      if (!this.form.message) {
        this.fireError('Send a message please');
        return;
      }

      this.request(`requests/${this.item.request_id}`, 'put', this.form, () => {
        this.fireSuccess('Request Log updated successfully');
        this.$emit('saved');
        this.close();
      });
    },
    startResponse() {
      this.answering = true;
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;

  li {
    b {
      display: block;
      margin-left: -1em;
      margin-top: .5em;
    }
    small {
      display: block;
      opacity: .5;
    }
  }
}
</style>
