<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      <b>{{ item.request_type.title }}</b>
    </template>
    <template
      v-if="result"
      slot="body"
    >
      <ul :key="result.updated_at">
        <li>
          <b>Date</b>
          <span>
            {{ dateFormat(result.date) }}
          </span>
        </li>
        <li>
          <b>Status</b>
          <span>
            {{ result.status }}
          </span>
        </li>
        <li
          v-for="(attr, i) of result.attributes"
          :key="i"
        >
          <template v-if="i !== 'file'">
            <b>{{ i.split('_').join(' ').ucwords() }} </b>
            <span>
              {{ isDate(attr) ? dateFormat(attr) : attr }}
            </span>
          </template>
        </li>
        <template v-if="result.attributes.file && result.attributes.file.length">
          <li class="pt-4">
            <b>Files</b>
          </li>
          <request-file-uploader
            v-for="(file, b) of result.attributes.file"
            :key="b+result.updated_at"
            class="file-item"
            :item="item"
            :file="file"
            @saved="onFileUpdated"
            @loading="loading = $event"
          />
        </template>
        <li v-if="result.response">
          <b>Staff response </b> {{ result.response }}
        </li>
      </ul>

      <div
        v-if="answering"
        class="response"
      >
        <form-textarea
          v-model="form.response"
          label="Response to Student"
        />
      </div>

      <md-button
        v-if="item.letter"
        class="md-info md-sm md-block"
        @click="getLetterUrl(item.letter.pdf)"
      >
        DOWNLOAD LETTER
      </md-button>
    </template>
    <template slot="footer">
      <template v-if="answering">
        <md-button
          class="md-secondary md-sm"
          @click="updateRequest('Cancelled')"
        >
          CANCELLED
        </md-button>
        <md-button
          class="md-danger md-sm"
          @click="updateRequest('Denied')"
        >
          NOT APPROVED
        </md-button>
        <md-button
          v-if="item.request_type.requires_collection"
          class="md-success md-sm"
          @click="updateRequest('Ready for Collection')"
        >
          READY
        </md-button>
        <md-button
          v-else
          class="md-success md-sm"
          @click="updateRequest('Completed')"
        >
          COMPLETED
        </md-button>
      </template>

      <md-button
        v-else-if="item.status === 'Ready for Collection'"
        class="md-success md-sm"
        @click="updateRequest('Completed')"
      >
        COMPLETED
      </md-button>

      <md-button
        v-else
        class="md-primary md-sm"
        :disabled="item.status !== 'Pending'"
        @click="startResponse"
      >
        SEND RESPONSE
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
/* eslint-disable camelcase */
import { FormTextarea } from '@/components/Inputs';
import RequestFileUploader from './RequestFileUploader.vue';

export default {
  components: {
    FormTextarea,
    RequestFileUploader,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    answering: false,
    form: {
      response: null,
      status: null,
    },
    result: null,
    loading: false,
  }),
  mounted() {
    this.getRequest();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    getRequest() {
      this.loading = true;
      this.request(`requests/${this.item.request_id}`, 'get', { student_id: this.item.student_id }, ({ data }) => {
        this.result = data;
      }, () => {
        this.loading = false;
      });
    },
    updateRequest(status) {
      if (!this.item.response && !this.form.response) {
        this.fireError('Send a response please');
        return;
      }
      this.loading = true;

      this.form.status = status;

      this.request(`requests/${this.item.request_id}`, 'put', this.form, () => {
        this.fireSuccess('Request updated successfully');
        this.$emit('saved');
        this.close();
      }, () => {
        this.loading = false;
      });
    },
    startResponse() {
      this.answering = true;
    },
    onFileUpdated(res) {
      console.log(res);
      this.result.attributes = res.attributes;
      this.result.updated_at = res.updated_at;
      this.loading = false;
    },
    isDate(value) {
      return /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(value);
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  padding: 0;

  li {
    b {
      display: block;
      margin-top: .5em;
    }
    span {
      display: block;
      margin-left: .5em;
    }
  }
}
</style>
